<template>
  <v-container fluid>
    <v-dialog v-model="dialogDeleteSupplier" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>Удалить поставщика?</v-card-title>
        <v-card-text
          >После подтверждения поставщик вместе со всеми настройками и товарами будет удален из базы данных</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="dialogDeleteSupplier = false" outline>Закрыть</v-btn>
          <v-btn color="error" @click="deleteSupplier" :loading="loading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProduct" persistent max-width="400px">
      <v-card>
        <v-card-title v-if="productId" class="headline" primary-title>Удалить товар?</v-card-title>
        <v-card-title v-else class="headline" primary-title>Удалить выбранные товары?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="closeDialogDeleteProduct" outline>Закрыть</v-btn>
          <v-btn color="error" @click="deleteProducts" :loading="loading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTransferProduct" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Перенести в категорию</v-card-title>
        <v-card-text>
          <category-autocomplete v-model="transferCategoryId" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="closeDialogTransferProduct" outline>Закрыть</v-btn>
          <v-btn color="info" @click="transferProducts">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVisibleProduct" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>Изменить видимость товаров</v-card-title>
        <v-card-text>
          <v-btn
            flat
            icon
            small
            :color="productsVisible ? 'green' : 'error'"
            @click="toggleProductsVisible"
            class="mx-0"
          >
            <v-icon>circle</v-icon>
          </v-btn>
          <span class="ml-1 v-label theme--light">{{
            productsVisible ? 'Сделать товары видимыми' : 'Сделать товары невидимыми'
          }}</span>
          <v-checkbox v-model="visibilityAll" box label="Применить для всех товаров"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="closeDialogVisibleProduct" outline>Закрыть</v-btn>
          <v-btn color="info" @click="changeProductsVisibility">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFilter" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Фильтры</v-card-title>
        <v-card-text>
          <category-autocomplete v-model="filterCategory" />
          <v-select v-model="filterChargeType" :items="CHARGE_TYPE" box clearable label="Наценка" required></v-select>
          <v-checkbox v-model="filterError" box label="С ошибкой"></v-checkbox>
          <v-checkbox v-model="filterActive" box label="Только активные"></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="saveFilter" outline>Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <supplier-conditions-dialog
      v-model="editConditions"
      :show="dialogConditionsSupplier"
      @close="dialogConditionsSupplier = false"
      @save="updateConditions"
      :loading="loading"
    />
    <supplier-edit-dialog
      v-model="editSupplier"
      :show="dialogEditSupplier"
      :loading="loading"
      @close="dialogEditSupplier = false"
      @save="updateSupplier"
    />
    <product-edit-dialog
      :product="product"
      :currency="currency"
      :show="dialogEditProduct"
      @close="dialogEditProduct = false"
      @save="saveProduct"
    />
    <v-layout>
      <v-container v-if="supplier" fluid white>
        <v-layout row wrap>
          <v-flex xs6>
            <v-btn outline fab small to="/catalog" class="mt-0 ml-0">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <span class="title">{{ supplier.title }}</span>
            <v-btn flat icon @click="openDialogEditSupplier" color="info">
              <v-icon>edit</v-icon>
            </v-btn>
            <span class="grey--text mr-1">#</span><span>{{ supplier.code }}</span>
            <v-btn color="info" @click="openDialogConditionsSupplier" outline>Условия</v-btn>
          </v-flex>
          <v-flex xs6 class="text-xs-right">
            <v-btn
              flat
              icon
              small
              @click="toggleSupplierVisibility"
              :loading="loadingSupplierVisibility"
              :color="supplier.visible ? 'green' : 'grey'"
            >
              <v-icon>circle</v-icon>
            </v-btn>
            <v-btn color="error" @click="dialogDeleteSupplier = true" outline>Удалить поставщика</v-btn>
          </v-flex>
          <v-flex xs12>
            <v-divider class="my-2"></v-divider>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex md3>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Поиск"
              class="pt-0"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-flex>
          <v-flex md3 class="d-flex align-start">
            <div class="filter-button">
              <v-btn color="info" flat @click="dialogFilter = true">
                Фильтры
                <v-icon right dark>filter_alt</v-icon>
              </v-btn>
            </div>
            <div class="filter-status">
              <v-icon v-if="filterStatus" color="success" class="mt-2">check_circle_outline</v-icon>
            </div>
          </v-flex>
          <v-flex md6 class="text-xs-right">
            <template v-if="selected.length">
              <span class="grey--text mr-1">Групповые операции</span>
              <v-btn flat icon color="info" @click="dialogTransferProduct = true" class="mr-0">
                <v-icon>drive_file_move_outline</v-icon>
              </v-btn>
              <v-btn flat icon @click="dialogVisibleProduct = true" color="info" class="mr-0">
                <v-icon>radio_button_unchecked</v-icon>
              </v-btn>
              <v-btn flat icon @click="dialogDeleteProduct = true" color="error">
                <v-icon>clear</v-icon>
              </v-btn>
            </template>
            <v-btn color="info" :to="`/supplier/${supplier.id}/update-catalog`" outline>Обновить каталог</v-btn>
            <v-btn color="info" @click="openDialogNewProduct">Добавить</v-btn>
          </v-flex>
        </v-layout>
        <v-data-table
          v-if="!loading"
          v-model="selected"
          :headers="headers"
          :items="products"
          :loading="loadingProducts"
          :pagination.sync="pagination"
          :total-items="total"
          :rows-per-page-items="[10, 50, 100]"
          must-sort
          select-all
          class="mt-3"
        >
          <template v-slot:items="props">
            <td>
              <v-checkbox v-model="props.selected" color="info" hide-details></v-checkbox>
            </td>
            <td class="text-xs-right">{{ props.item.id }}</td>
            <td class="text-xs-right">{{ props.item.original_vendor_code }}</td>
            <td class="text-xs-right">
              <div class="my-2">
                <v-img :src="props.item.photo" height="50"></v-img>
              </div>
            </td>
            <td>{{ props.item.name }}</td>
            <td>{{ showCategoryName(props.item.categories) }}</td>
            <td>{{ props.item.cost_price }}</td>
            <td>{{ props.item.full_cost_price }}</td>
            <td>{{ props.item.charge }}{{ props.item.charge_type === 1 ? '%' : '' }}</td>
            <td>{{ props.item.price }}</td>
            <td>{{ props.item.full_price }}</td>
            <td class="text-xs-right">{{ props.item.priority }}</td>
            <td class="text-xs-right">
              <div style="display: inline-block; width: 90px">
                <v-btn
                  flat
                  icon
                  small
                  :color="props.item.visible ? 'green' : 'error'"
                  @click="toggleProductVisibility(props.item)"
                  class="mx-0"
                >
                  <v-icon>circle</v-icon>
                </v-btn>
                <v-btn flat icon small @click="openDialogEditProduct(props.item)" color="info" class="mx-0">
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn flat icon small @click="openDialogDeleteProduct(props.item.id)" color="error" class="mx-0">
                  <v-icon>clear</v-icon>
                </v-btn>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import { CHARGE_TYPE, CURRENCIES, CURRENCY_SYMBOL } from '@/constants.js'
import SupplierEditDialog from '@/components/dialogs/SupplierEditDialog'
import SupplierConditionsDialog from '@/components/dialogs/SupplierConditionsDialog'
import ProductEditDialog from '@/components/dialogs/ProductEditDialog'
import CategoryAutocomplete from '@/components/inputs/CategoryAutocomplete'

export default {
  components: { SupplierEditDialog, SupplierConditionsDialog, ProductEditDialog, CategoryAutocomplete },

  data: () => ({
    CURRENCIES,
    CHARGE_TYPE,
    CURRENCY_SYMBOL,
    supplier: {},
    conditions: [],
    editConditions: [],
    editSupplier: {},
    product: {},
    selected: [],
    headers: [],
    loading: false,
    loadingProducts: false,
    loadingSupplierVisibility: false,
    categoryTitle: '',
    products: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
    },
    total: 0,
    search: '',
    dialogDeleteSupplier: false,
    dialogDeleteProduct: false,
    dialogEditSupplier: false,
    dialogEditProduct: false,
    dialogVisibleProduct: false,
    dialogFilter: false,
    dialogTransferProduct: false,
    dialogConditionsSupplier: false,
    imgOpenSrc: null,
    category: null,
    filterCategory: null,
    filterError: false,
    filterActive: false,
    filterChargeType: null,
    transferCategoryId: null,
    productsVisible: true,
    productId: null,
    visibilityAll: false,
  }),

  computed: {
    ...mapState({ categoriesCombobox: (state) => state.categories.categories }),

    currency() {
      return this.supplier.currency === CURRENCIES.RUB.value ? CURRENCIES.RUB.name : CURRENCIES.BYN.name
    },

    filterStatus() {
      return this.filterError || this.filterActive || this.filterCategory || this.filterChargeType
    },
  },

  watch: {
    pagination: {
      async handler() {
        await this.getProducts()
      },
      deep: true,
    },
    search() {
      this.getProducts()
    },
  },

  async mounted() {
    await this.getSupplier(this.$route.params.id)
    this.updateTableColumns()
    this.getConditions()
  },

  methods: {
    async getSupplier(id) {
      this.loading = true
      const response = await this.axios.get(`/api/admin/suppliers/${id}`)
      this.supplier = response.data.data
      this.loading = false
    },

    getProducts: debounce(async function () {
      this.loadingProducts = true
      let params = `paginate=${this.pagination.rowsPerPage}&page=${this.pagination.page}`
      params += `&orderBy=${this.pagination.sortBy}&orderByDesc=${this.pagination.descending ? '1' : '0'}`
      if (this.search) {
        params += `&search=${this.search}`
      }
      if (this.filterCategory) {
        params += `&category=${this.filterCategory}`
      }
      if (this.filterChargeType) {
        params += `&charge_type=${this.filterChargeType}`
      }
      if (this.filterError) {
        params += `&error_cat=${this.filterError ? 1 : 0}`
      }
      if (this.filterActive) {
        params += `&visible=${this.filterActive ? 1 : 0}`
      }
      const response = await this.axios.get(`/api/admin/suppliers/${this.$route.params.id}/products?${params}`)
      this.products = response.data.data.data
      this.total = response.data.data.total
      this.loadingProducts = false
    }, 500),

    async getConditions() {
      const response = await this.axios.get(`/api/admin/suppliers/${this.supplier.id}/charges`)
      this.conditions = response.data.data
    },

    async updateConditions() {
      this.loading = true
      await this.axios.put(`/api/admin/suppliers/${this.supplier.id}/charges`, {
        charges: this.editConditions,
      })
      await this.getConditions()
      this.loading = false
      this.dialogConditionsSupplier = false
    },

    async updateSupplier() {
      this.loading = true
      const response = await this.axios.patch(`/api/admin/suppliers/${this.supplier.id}`, {
        title: this.editSupplier.title,
        alias: this.editSupplier.alias,
        currency: this.editSupplier.currency,
        exchange_type: this.editSupplier.exchange_type,
      })
      this.supplier = response.data.data
      this.updateTableColumns()
      this.loading = false
      this.dialogEditSupplier = false
    },

    async deleteSupplier() {
      this.loading = true
      await this.axios.delete(`/api/admin/suppliers/${this.supplier.id}`)
      this.loading = false
      this.$router.push('/catalog')
    },

    async deleteProducts() {
      this.loading = true
      const data = {}
      if (this.productId) {
        data['products'] = [this.productId]
      } else {
        data['products'] = this.selected.map((item) => item.id)
      }
      await this.axios.delete(`/api/admin/suppliers/${this.supplier.id}/products`, { data })
      await this.getProducts()
      this.loading = false
      this.closeDialogDeleteProduct()
    },

    async transferProducts() {
      this.loading = true
      const data = {
        category: this.transferCategoryId,
      }
      data['products'] = this.selected.map((item) => item.id)
      await this.axios.patch(`/api/admin/suppliers/${this.supplier.id}/products/category`, data)
      await this.getProducts()
      this.loading = false
      this.closeDialogTransferProduct()
    },

    async changeProductsVisibility() {
      this.loading = true
      const data = {
        visible: this.productsVisible ? 1 : 0,
      }
      if (this.visibilityAll) {
        data['all_products'] = 1
      }
      data['products'] = this.selected.map((item) => item.id)
      await this.axios.patch(`/api/admin/suppliers/${this.supplier.id}/products/visible`, data)
      await this.getProducts()
      this.loading = false
      this.closeDialogVisibleProduct()
    },

    openDialogEditProduct(product) {
      this.product = JSON.parse(JSON.stringify(product))
      this.dialogEditProduct = true
    },

    openDialogNewProduct() {
      this.product = {
        is_guarantee: 0,
        is_charge_manually: 0,
        charge_type: 0,
      }
      this.dialogEditProduct = true
    },

    openDialogDeleteProduct(id) {
      this.productId = id
      this.dialogDeleteProduct = true
    },

    closeDialogDeleteProduct() {
      this.productId = null
      this.dialogDeleteProduct = false
    },

    closeDialogTransferProduct() {
      this.dialogTransferProduct = false
    },

    closeDialogVisibleProduct() {
      this.dialogVisibleProduct = false
      this.visibilityAll = false
      this.productsVisible = true
    },

    openDialogTransferProduct(id) {
      this.productId = id
      this.dialogTransferProduct = true
    },

    openDialogEditSupplier() {
      this.editSupplier = { ...this.supplier }
      this.dialogEditSupplier = true
    },

    openDialogConditionsSupplier() {
      this.editConditions = this.conditions.map((c) => ({
        total_from: c.total_from,
        total_to: c.total_to,
        type: c.type,
        amount: c.amount,
      }))
      this.dialogConditionsSupplier = true
    },

    async toggleSupplierVisibility() {
      this.loadingSupplierVisibility = true
      await this.axios.patch(`/api/admin/suppliers/${this.supplier.id}`, {
        visible: !this.supplier.visible,
      })
      await this.getSupplier(this.supplier.id)
      this.loadingSupplierVisibility = false
    },

    async toggleProductVisibility(product) {
      try {
        await this.axios.patch(`/api/admin/suppliers/${this.supplier.id}/products/visible`, {
          visible: !product.visible ? 1 : 0,
          products: [product.id],
        })
        product.visible = !product.visible
      } catch (e) {
        console.log(e)
      }
    },

    async saveProduct() {
      await this.getProducts()
      this.loading = false
      this.dialogEditProduct = false
    },

    showCategoryName(categories) {
      if (categories.length === 0) {
        return '—'
      }
      if (categories.length === 1) {
        return categories[0].name
      }
      if (categories.length > 1) {
        return `${categories[0].name} [+${categories.length - 1}]`
      }
    },

    saveFilter() {
      this.getProducts()
      this.dialogFilter = false
    },

    toggleProductsVisible() {
      this.productsVisible = !this.productsVisible
    },

    updateTableColumns() {
      this.headers = [
        { text: '#', value: 'id', sortable: true },
        { text: 'Артикул', value: 'original_vendor_code', sortable: false },
        { text: 'Изображение', value: 'photo', sortable: false },
        { text: 'Наименование и тип', value: 'name', sortable: false, class: 'multiline' },
        { text: 'Категория', value: 'category', sortable: false },
        { text: `Себестоимость, ${this.currency}`, value: 'cost_price', sortable: true, class: 'multiline' },
        {
          text: `Себестоимость без скидки, ${this.currency}`,
          value: 'full_cost_price',
          sortable: false,
          class: 'multiline',
        },
        { text: `Наценка, ${this.currency}`, value: 'charge', sortable: true, class: 'multiline' },
        { text: 'Цена, ' + this.CURRENCY_SYMBOL, value: 'price', sortable: false, class: 'multiline' },
        { text: 'Цена без скидки, ' + this.CURRENCY_SYMBOL, value: 'full_price', sortable: false, class: 'multiline' },
        { text: 'Приоритет', value: 'priority', sortable: false },
        { text: 'Действия', sortable: false },
      ]
    },
  },
}
</script>

<style scoped>
.filter-button {
  flex: 0 0 auto !important;
}

.filter-status {
  padding-top: 0.3rem !important;
}
</style>
