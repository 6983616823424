<template>
  <v-container fluid>
    <v-dialog v-model="dialogCategory" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title v-if="!category.id" class="headline grey lighten-2" primary-title>Новая категория</v-card-title>
        <v-card-title v-else class="headline grey lighten-2" primary-title>Редактирование категории</v-card-title>
        <v-card-text>
          <div class="mb-4">
            <div v-if="catImgOpenSrc" class="img">
              <v-img :src="catImgOpenSrc" height="100"></v-img>
              <v-btn icon color="red" @click="deleteCategoryImage" class="img-del-button white--text">
                <v-icon>clear</v-icon>
              </v-btn>
            </div>
            <div v-else class="img-placeholder">
              <v-btn fab dark color="grey" @click="openFileDialog">
                <v-icon dark>add</v-icon>
              </v-btn>
              <input v-show="false" ref="file-new" type="file" accept="image/*" @change="setCategoryImage($event)" />
            </div>
          </div>
          <v-text-field v-model="category.name" box label="Название категории" required></v-text-field>
          <v-text-field v-model="category.alias" box label="Alias" required></v-text-field>
          <v-textarea v-model="category.description" box label="Описание" rows="5" required></v-textarea>
          <category-autocomplete v-model="category.parent" label="Привязать к категории" />
          <v-text-field
            v-if="category.id"
            v-model.number="category.show_order"
            box
            label="Порядок"
            type="number"
            required
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="closeDialogCategory" outline>Закрыть</v-btn>
          <v-btn v-if="!category.id" color="info" @click="createCategory(category)" :loading="loading">Создать</v-btn>
          <v-btn v-else color="info" @click="updateCategory(category)" :loading="loading">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <supplier-edit-dialog
      v-model="supplier"
      :show="dialogSupplier"
      :loading="loading"
      @close="dialogSupplier = false"
      @save="createSupplier"
    />
    <brand-edit-dialog
      v-model="brand"
      :show="dialogBrand"
      :loading="loading"
      @close="dialogBrand = false"
      @save="createBrand"
    />
    <v-dialog v-model="dialogDeleteCategory" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>Удалить категорию "{{ category.name }}"?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" outline @click="dialogDeleteCategory = false">Закрыть</v-btn>
          <v-btn color="error" @click="deleteCategory(category.id)" :loading="loading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteBrand" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>Удалить бренд "{{ brand.title }}"?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" outline @click="dialogDeleteBrand = false">Закрыть</v-btn>
          <v-btn color="error" @click="deleteBrand(brand.id)" :loading="loading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-expansion-panel>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div class="catalog-header">
              <div class="catalog-title ml-3">Курсы валют</div>
              <v-btn @click.stop="saveExchangeRate" color="info">Сохранить</v-btn>
            </div>
          </template>
          <v-container fluid grid-list-lg white>
            <v-data-table :headers="headers" :items="exchangeRates" class="elevation-1" hide-actions>
              <template v-slot:items="props">
                <td>
                  <strong>{{ getCurrencyDescriptionById(props.item.currency) }}</strong>
                </td>
                <td class="text-xs-left">{{ getCurrencyNameById(props.item.currency) }}</td>
                <td class="text-xs-left">
                  <v-edit-dialog :return-value.sync="props.item.internal_exchange_rate" lazy>
                    {{ props.item.internal_exchange_rate }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.internal_exchange_rate"
                        label="Ввести курс"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-xs-left">{{ props.item.bank_exchange_rate }}</td>
                <td class="text-xs-left">{{ props.item.bank_exchange_rate_updated_at }}</td>
              </template>
            </v-data-table>
          </v-container>
        </v-expansion-panel-content>

        <v-expansion-panel-content>
          <template v-slot:header>
            <div class="catalog-header">
              <div class="catalog-title ml-3">Структура каталога</div>
              <v-btn @click.stop="openDialogCategory" color="info">Добавить</v-btn>
            </div>
          </template>
          <v-container fluid grid-list-lg white>
            <v-layout wrap align-center>
              <v-flex xs12 sm6 lg4 d-flex>
                <v-select
                  v-model="catalogViewType"
                  :items="CATALOG_TYPE"
                  label="Тип отображения категорий каталога"
                  @change="changeCatalogViewType"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container fluid grid-list-lg white>
            <v-breadcrumbs :items="breadcrumbs" divider=">">
              <template v-slot:item="props">
                <a @click="getCategories(props.item.id)" :class="{ disabled: props.item.disabled }">{{
                  props.item.label
                }}</a>
              </template>
            </v-breadcrumbs>
            <v-layout row wrap>
              <v-flex v-for="cat of categories" :key="cat.id" xs12 sm4 md3>
                <v-card>
                  <v-toolbar flat dense>
                    <v-spacer></v-spacer>
                    <v-btn
                      flat
                      icon
                      small
                      :color="cat.visible ? 'green' : 'grey'"
                      @click="toggleCategoryVisibility(cat)"
                    >
                      <v-icon>circle</v-icon></v-btn
                    >
                    <v-btn flat icon small color="info" @click="openDialogEditCategory(cat)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn flat icon small color="red" @click="openDialogDeleteCategory(cat)">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <a v-if="cat.image_url" @click="getCategories(cat.id)">
                    <v-img :src="cat.image_url" height="100"></v-img>
                  </a>
                  <div v-else class="img-placeholder">
                    <v-icon x-large>image</v-icon>
                  </div>
                  <div class="card-footer">
                    <span class="subheading">{{ cat.name }}</span>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div class="catalog-header">
              <div class="catalog-title ml-3">Поставщики</div>
              <v-btn @click.stop="openDialogSupplier" color="info">Добавить</v-btn>
            </div>
          </template>
          <v-container fluid grid-list-md white>
            <v-layout row wrap>
              <v-flex v-for="supplier of suppliers" :key="supplier.id" xs12 sm4 md3>
                <router-link :to="`/supplier/${supplier.id}`">
                  <div class="d-flex justify-center supplier">
                    <h3 class="headline font-weight-bold text-xs-center info--text">{{ supplier.title }}</h3>
                  </div>
                </router-link>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div class="catalog-header">
              <div class="catalog-title ml-3">Бренды</div>
              <v-btn @click.stop="openDialogBrand" color="info">Добавить</v-btn>
            </div>
          </template>
          <v-container fluid grid-list-md white>
            <v-layout row wrap>
              <v-flex v-for="brand of brands" :key="brand.id" xs12 sm4 md3>
                <div class="brand">
                  <div class="delete-brand-button">
                    <v-btn flat icon @click="openDialogDeleteBrand(brand)" color="error">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </div>
                  <h3 class="headline font-weight-bold text-xs-center grey--text text--darken-2">{{ brand.title }}</h3>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { CURRENCIES } from '@/constants.js'
import SupplierEditDialog from '@/components/dialogs/SupplierEditDialog'
import BrandEditDialog from '@/components/dialogs/BrandEditDialog'
import CategoryAutocomplete from '@/components/inputs/CategoryAutocomplete'

const CATALOG_TYPE = [
  { text: 'Активные с товарами', value: 0 },
  { text: 'Все активные', value: 1 },
]

const BREADCRUMBS_ROOT = {
  label: 'Каталог',
  disabled: false,
  id: -1,
}

const CATEGORY_ROOT = {
  value: -1,
  text: 'Каталог',
}

export default {
  components: { SupplierEditDialog, BrandEditDialog, CategoryAutocomplete },

  data: () => ({
    exchangeRates: [],
    breadcrumbs: [BREADCRUMBS_ROOT],
    categories: null,
    category: {},
    parentCategory: CATEGORY_ROOT,
    catImgOpenSrc: null,
    catImgOpenFile: null,
    suppliers: [],
    supplier: {},
    brand: {},
    loading: false,
    categoryTitle: '',
    dialogCategory: false,
    dialogSupplier: false,
    dialogBrand: false,
    dialogDeleteBrand: false,
    dialogDeleteCategory: false,
    total: 0,
    search: '',
    test: '',
    CATALOG_TYPE,
    catalogViewType: 0,
    headers: [
      {
        text: '',
        align: 'left',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Тикет',
        align: 'left',
        sortable: false,
        value: 'currency',
      },
      {
        text: 'Внутренний курс',
        align: 'left',
        sortable: false,
        value: 'internal_exchange_rate',
      },
      {
        text: 'Курс НБ РБ',
        align: 'left',
        sortable: false,
        value: 'bank_exchange_rate',
      },
      {
        text: 'Последнее обновление',
        align: 'left',
        sortable: false,
        value: 'bank_exchange_rate_updated_at',
      },
    ],
  }),

  computed: {
    ...mapState({ categoriesCombobox: (state) => state.categories.categories }),
    ...mapState({ brands: (state) => state.brands.brands }),
  },

  async mounted() {
    this.getExchangeRate()
    this.getCategories(-1)
    this.getCatalogViewType()
    this.getSuppliers()
  },

  methods: {
    ...mapActions(['fetchCategories', 'fetchBrands']),

    async getExchangeRate() {
      const response = await this.axios.get('/api/exchange')
      this.exchangeRates = response.data.exchanges
    },

    async saveExchangeRate() {
      const exchanges = []
      for (let e of this.exchangeRates) {
        exchanges.push({
          currency: e.currency,
          internal_exchange_rate: e.internal_exchange_rate,
        })
      }
      await this.axios.post('/api/exchange', { exchanges })
    },

    async getCategories(parentId) {
      await this.getBreadcrumbs(parentId)
      const response = await this.axios.get(
        parentId === -1 ? '/api/admin/categories' : `/api/admin/categories/${parentId}/subcategories`
      )
      this.categories = response.data.data
    },

    async getBreadcrumbs(id) {
      if (id === -1) {
        this.breadcrumbs = [BREADCRUMBS_ROOT]
        this.breadcrumbs[0].disabled = true
        this.parentCategory = CATEGORY_ROOT
      } else {
        const response = await this.axios.get(`/api/admin/categories/${id}`)
        const bc = response.data.data.breadcrumbs
        this.parentCategory = { value: response.data.data.id, text: response.data.data.label }
        this.breadcrumbs = [BREADCRUMBS_ROOT]
        this.breadcrumbs[0].disabled = false
        for (let b of bc) {
          this.breadcrumbs.push({
            label: b.label,
            id: b.id,
            disabled: false,
          })
        }
        this.breadcrumbs[this.breadcrumbs.length - 1].disabled = true
      }
    },

    async getSuppliers() {
      const response = await this.axios.get('/api/admin/suppliers')
      this.suppliers = response.data.data
    },

    openDialogCategory() {
      this.category = { parent: this.parentCategory.value }
      this.dialogCategory = true
      this.catImgOpenSrc = null
      this.catImgOpenFile = null
    },

    openDialogEditCategory(cat) {
      this.category = { ...cat, parent: this.parentCategory.value }
      for (let catCombo of this.categoriesCombobox) {
        if (cat.id === catCombo.id) {
          catCombo.disabled = true
        }
      }
      this.catImgOpenSrc = cat.img
      this.catImgOpenFile = null
      this.dialogCategory = true
    },

    openDialogDeleteCategory(cat) {
      this.category = cat
      this.dialogDeleteCategory = true
    },

    openDialogDeleteBrand(brand) {
      this.brand = brand
      this.dialogDeleteBrand = true
    },

    closeDialogCategory() {
      this.dialogCategory = false
    },

    openDialogSupplier() {
      this.supplier = { currency: CURRENCIES.RUB.value }
      this.dialogSupplier = true
    },

    openDialogBrand() {
      this.dialogBrand = true
    },

    async toggleCategoryVisibility(cat) {
      cat.visible = !cat.visible
      await this.axios.post(`/api/admin/categories/${cat.id}`, { visible: cat.visible })
    },

    openFileDialog() {
      this.$refs['file-new'].click()
    },

    setCategoryImage(event) {
      this.catImgOpenFile = event.target.files[0]
      this.catImgOpenSrc = URL.createObjectURL(event.target.files[0])
    },

    deleteCategoryImage() {
      this.catImgOpenSrc = null
      this.catImgOpenFile = null
    },

    async deleteCategory(id) {
      this.loading = true
      await this.axios.delete(`/api/admin/categories/${id}`)
      await this.getCategories(this.parentCategory.value)
      this.fetchCategories()
      this.loading = false
      this.dialogDeleteCategory = false
    },

    async deleteBrand(id) {
      this.loading = true
      await this.axios.delete(`/api/admin/brands/${id}`)
      await this.fetchBrands()
      this.loading = false
      this.dialogDeleteBrand = false
    },

    async createCategory(cat) {
      this.loading = true
      const formData = new FormData()
      formData.append('name', cat.name)
      formData.append('alias', cat.alias)
      formData.append('description', cat.description)
      formData.append('parent_cat_id', this.parentCategory.value)
      if (this.catImgOpenFile) {
        formData.append('image', this.catImgOpenFile, this.catImgOpenFile.name)
      }
      await this.axios.post('/api/admin/categories', formData)
      await this.getCategories(cat.parent)
      this.fetchCategories()
      this.loading = false
      this.dialogCategory = false
    },

    async updateCategory(cat) {
      this.loading = true
      const formData = new FormData()
      formData.append('name', cat.name)
      formData.append('alias', cat.alias)
      formData.append('description', cat.description)
      formData.append('show_order', cat.show_order)
      formData.append('parent_cat_id', cat.parent)
      if (this.catImgOpenFile) {
        formData.append('image', this.catImgOpenFile, this.catImgOpenFile.name)
      }
      await this.axios.post(`/api/admin/categories/${cat.id}`, formData)
      await this.getCategories(cat.parent)
      this.fetchCategories()
      this.loading = false
      this.dialogCategory = false
    },

    async createSupplier() {
      this.loading = true
      await this.axios.post('/api/admin/suppliers', {
        title: this.supplier.title,
        alias: this.supplier.alias,
        currency: this.supplier.currency,
        exchange_type: this.supplier.exchange_type,
      })
      await this.getSuppliers()
      this.loading = false
      this.dialogSupplier = false
    },

    async createBrand() {
      this.loading = true
      await this.axios.post('/api/admin/brands', {
        title: this.brand.title,
        alias: this.brand.alias,
      })
      await this.fetchBrands()
      this.loading = false
      this.dialogBrand = false
    },

    async changeCatalogViewType() {
      await this.axios.patch('/api/admin/categories/visible', {
        products_exist: this.catalogViewType === 0 ? true : false,
      })
    },

    async getCatalogViewType() {
      const response = await this.axios.get('/api/admin/categories/visible')
      this.catalogViewType = response.data.data.products_exist ? 0 : 1
    },

    getCurrencyDescriptionById(id) {
      return Object.values(CURRENCIES).find((c) => c.value === id).description
    },

    getCurrencyNameById(id) {
      return Object.values(CURRENCIES).find((c) => c.value === id).name
    },
  },
}
</script>

<style scoped>
.catalog-header {
  order: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.catalog-title {
  font-weight: bold;
  vertical-align: center;
}

.order {
  max-width: 80px;
}

.supplier {
  border: 1px solid #2196f3;
  padding: 20px 10px;
}

.brand {
  position: relative;
  border: 1px solid #616161;
  padding: 20px 10px;
}

.delete-brand-button {
  position: absolute;
  top: 0;
  right: 0;
}

.img {
  position: relative;
}

.img-placeholder {
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.img-del-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}

.v-breadcrumbs {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

a {
  text-decoration: none !important;
}

.card-footer {
  padding: 20px;
}

.indent-0 {
  padding-left: 0;
}

.indent-1 {
  padding-left: 20px;
}

.indent-2 {
  padding-left: 40px;
}

.indent-3 {
  padding-left: 60px;
}

.indent-4 {
  padding-left: 80px;
}

.disabled {
  color: grey;
  pointer-events: none;
}
</style>
