<template>
  <v-container fluid>
    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Предупреждение на главной странице</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field label="Заголовок" v-model="mainPageWarning.title"></v-text-field>
        <v-text-field label="Текст" v-model="mainPageWarning.description"></v-text-field>
        <v-checkbox label="Включено" v-model="mainPageWarning.visible"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveMainPageWarning()" color="info">Сохранить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Преимущества</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-for="(plus, index) in pluses"
          :key="index"
          :label="`Строка ${index + 1}`"
          @click:append-outer="removePlus(plus)"
          append-outer-icon="cancel"
          v-model="plus.plus"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="savePluses()" color="info">Сохранить</v-btn>
        <v-btn @click="addPlus()" color="success">Добавить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Популярные категории</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="i" xs12 sm6 md4 lg3>
              <v-card>
                <div class="img-placeholder" v-if="!imgPopularSrc[i]">
                  <v-btn fab dark color="grey" @click="openFileDialog('popular', i)">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <input
                    v-show="false"
                    :ref="`popular-file-${i}`"
                    type="file"
                    @change="setPopularCategoryImage(i, $event)"
                  />
                </div>
                <v-img v-if="imgPopularSrc[i]" :src="imgPopularSrc[i]" height="150px"></v-img>
                <v-btn
                  v-if="imgPopularSrc[i]"
                  flat
                  icon
                  color="red"
                  @click="deletePopularCategoryImage(i)"
                  class="img-del-button"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-card-actions>
                  <category-autocomplete v-model="selectedPopularCategories[i]" @change="changePopularCategory(i)" />
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Рекомендуемые категории</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="i" xs12 sm6 md4 lg3>
              <v-card>
                <div class="img-placeholder" v-if="!imgRecommendedSrc[i]">
                  <v-btn fab dark color="grey" @click="openFileDialog('recommended', i)">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <input
                    v-show="false"
                    :ref="`recommended-file-${i}`"
                    type="file"
                    @change="setRecommendedCategoryImage(i, $event)"
                  />
                </div>
                <v-img v-if="imgRecommendedSrc[i]" :src="imgRecommendedSrc[i]" height="150px"></v-img>
                <v-btn
                  v-if="imgRecommendedSrc[i]"
                  flat
                  icon
                  color="red"
                  @click="deleteRecommendedCategoryImage(i)"
                  class="img-del-button"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-card-actions>
                  <category-autocomplete
                    v-model="selectedRecommendedCategories[i]"
                    @change="changeRecommendedCategory(i)"
                  />
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Новые поступления</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid pa-0 grid-list-xl>
          <v-layout row>
            <v-flex xs4>
              <v-autocomplete
                v-model="selectedProduct"
                :items="foundProducts"
                :loading="productsLoading"
                :search-input.sync="search"
                item-text="name"
                item-value="alias"
                label="Добавить новый товар"
                placeholder="Название товара"
                return-object
                hide-no-data
                hide-selected
                no-filter
                height="42px"
              >
                <template v-slot:item="data">
                  <v-list-tile class="pa-2">
                    <v-list-tile-avatar>
                      <img :src="data.item.photo" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                      <v-list-tile-sub-title v-html="data.item.alias"></v-list-tile-sub-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-btn fab dark small color="primary" @click="addProduct(data.item.id)">
                        <v-icon dark>add</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-container>
        <v-container pa-0 grid-list-xl text-xs-center fluid>
          <draggable
            v-model="newProducts"
            @change="moveProduct($event.moved.element.id, $event.moved.newIndex)"
            class="layout row wrap"
          >
            <v-flex v-for="p of newProducts" :key="p.alias" xs3>
              <v-card style="cursor: grab">
                <v-img :src="p.photo" contain height="150px">
                  <div class="text-xs-right">
                    <v-btn flat icon @click="removeProduct(p)" color="error">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </div>
                </v-img>
                <v-card-text style="height: 75px">
                  <div class="subheading">{{ p.name }}</div>
                </v-card-text>
              </v-card>
            </v-flex>
          </draggable>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CategoryAutocomplete from '@/components/inputs/CategoryAutocomplete'
import draggable from 'vuedraggable'

export default {
  components: { CategoryAutocomplete, draggable },

  data: function () {
    return {
      mainPageWarning: {},
      pluses: [],
      selectedPopularCategories: [],
      selectedRecommendedCategories: [],
      imgPopularSrc: [],
      imgRecommendedSrc: [],
      imgPopularFile: [],
      imgRecommendedFile: [],
      popularCategories: [],
      recommendedCategories: [],
      search: null,
      selectedProduct: null,
      newProducts: [],
      foundProducts: [],
      productsLoading: false,
    }
  },

  watch: {
    async search(value) {
      if (!value || value.length < 3) {
        this.foundProducts = []
        return
      }

      if (this.productsLoading) {
        return
      }

      this.productsLoading = true

      const response = await this.axios.get(
        `/api/site/products?limit=20&orderBy=priority&search=${value}&orderByDesc=1`
      )

      this.foundProducts = response.data.data
      this.productsLoading = false
    },
  },

  mounted() {
    this.getMainPageWarning()
    this.getPluses()
    this.getPopularCategroies()
    this.getRecommendedCategroies()
    this.getNewProducts()
  },

  methods: {
    getMainPageWarning() {
      this.axios.get('/api/admin/warning/get').then((response) => {
        this.mainPageWarning = response.data
      })
    },

    saveMainPageWarning() {
      this.axios.post('/api/admin/warning/save', this.mainPageWarning).then((response) => {
        this.mainPageWarning = response.data
      })
    },

    getPluses() {
      this.axios.get('/api/admin/pluses/list').then((response) => {
        this.pluses = response.data
      })
    },

    async getPopularCategroies() {
      const response = await this.axios.get('/api/admin/categories/popular')
      this.popularCategories = response.data.data
      this.setDefaultPopularCategories()
    },

    async getRecommendedCategroies() {
      const response = await this.axios.get('/api/admin/categories/recommended')
      this.recommendedCategories = response.data.data
      this.setDefaultRecommendedCategories()
    },

    async getNewProducts() {
      const response = await this.axios.get('/api/admin/products/new')
      this.newProducts = response.data.data
    },

    setDefaultPopularCategories() {
      for (let i = 0; i <= 9 && i < this.popularCategories.length; i++) {
        this.$set(this.selectedPopularCategories, i, this.popularCategories[i].cat_id)
        this.$set(this.imgPopularSrc, i, this.popularCategories[i].image_url)
      }
    },

    setDefaultRecommendedCategories() {
      for (let i = 0; i <= 9 && i < this.recommendedCategories.length; i++) {
        this.$set(this.selectedRecommendedCategories, i, this.recommendedCategories[i].cat_id)
        this.$set(this.imgRecommendedSrc, i, this.recommendedCategories[i].image_url)
      }
    },

    removePlus(plus) {
      if (plus.id && !window.confirm('Вы уверены?')) {
        return
      }

      if (plus.id) {
        this.axios.post('/api/admin/pluses/delete', { id: plus.id }).then(() => {
          this.getPluses()
        })
      } else {
        this.getPluses()
      }
    },

    addPlus() {
      this.pluses.push({
        plus: '',
        position: 100,
      })
    },

    savePluses() {
      this.axios
        .post('/api/admin/pluses/save', {
          pluses: this.pluses,
        })
        .then(() => {
          this.getPluses()
        })
    },

    openFileDialog(prefix, i) {
      this.$refs[`${prefix}-file-${i}`][0].click()
    },

    async setPopularCategoryImage(i, event) {
      this.$set(this.imgPopularFile, i, event.target.files[0])
      this.$set(this.imgPopularSrc, i, URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append('image', event.target.files[0], event.target.files[0].name)
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },

    async setRecommendedCategoryImage(i, event) {
      this.$set(this.imgRecommendedFile, i, event.target.files[0])
      this.$set(this.imgRecommendedSrc, i, URL.createObjectURL(event.target.files[0]))
      const formData = new FormData()
      formData.append('image', event.target.files[0], event.target.files[0].name)
      await this.axios.post(`/api/admin/categories/recommended/${this.recommendedCategories[i].id}`, formData)
    },

    async deletePopularCategoryImage(i) {
      this.$set(this.imgPopularFile, i, null)
      this.$set(this.imgPopularSrc, i, null)
      const formData = new FormData()
      formData.append('image', '')
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },

    async deleteRecommendedCategoryImage(i) {
      this.$set(this.imgRecommendedFile, i, null)
      this.$set(this.imgRecommendedSrc, i, null)
      const formData = new FormData()
      formData.append('image', '')
      await this.axios.post(
        `/api/admin/categories/recommended/${this.recommendedCategories[i] ? this.recommendedCategories[i].id : i}`,
        formData
      )
    },

    async changePopularCategory(i) {
      const formData = new FormData()
      formData.append('cat_id', this.selectedPopularCategories[i])
      formData.append('image', '')
      await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
    },

    async changeRecommendedCategory(i) {
      const formData = new FormData()
      formData.append('cat_id', this.selectedRecommendedCategories[i])
      formData.append('image', '')
      await this.axios.post(
        `/api/admin/categories/recommended/${this.recommendedCategories[i] ? this.recommendedCategories[i].id : i}`,
        formData
      )
    },

    // todo: stub
    async addRecommendedCategory() {
      const formData = new FormData()
      formData.append('cat_id', 711)
      formData.append('name', 'Тест')
      await this.axios.post(`/api/admin/categories/recommended`, formData)
    },

    async addProduct(productId) {
      for (let p of this.newProducts) {
        if (p.id === productId) {
          return
        }
      }

      this.newProducts.unshift(productId)
      await this.axios.post(`/api/admin/products/new`, {
        product_id: productId,
      })
      this.moveProduct(productId, 0)
    },

    async removeProduct(product) {
      this.newProducts = this.newProducts.filter((p) => p.id !== product.id)
      await this.axios.delete(`/api/admin/products/new/${product.id}`)
    },

    async moveProduct(productId, newIndex) {
      await this.axios.patch(`/api/admin/products/new/${productId}`, {
        show_order: newIndex,
      })
    },
  },
}
</script>

<style scoped>
.img-placeholder {
  display: flex;
  flex-direction: column;
  height: 150px;
  align-items: center;
  justify-content: center;
}
.img-del-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}

.action-header {
  order: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-title {
  font-weight: bold;
  vertical-align: center;
}

.order {
  max-width: 80px;
}

.box {
  padding: 15px;
  border: 1px solid #ccc;
}
</style>
